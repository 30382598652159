import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { CallModel } from '@smarttask-common/src/lib/models/CRM/Call/Recording/CallModel';

export enum CalllistActionTypes {
    ADD_CALL = '[Call] Create Call',
    UPSERT_CALL = '[Call] Upsert Call',
    ADD_CALLS = '[Call] Add Calls',
    UPSERT_CALLS = '[Call] Upsert Calls',
    UPDATE_CALL = '[Call] Update Call',
    UPDATE_CALLS = '[Call] Update Calls',
    DELETE_CALL = '[Call] Delete Call',
    DELETE_CALLS = '[Call] Delete Calls',
    DELETE_STATE = '[Call] Delete State',

    UPDATE_SELECTED_ID = '[Call] Update SelectedId',
}

export class UpdateSelectedCallId implements Action {
    readonly type = CalllistActionTypes.UPDATE_SELECTED_ID;
    constructor(public selected_call_id: number) {}
}

export class AddOne implements Action {
    readonly type = CalllistActionTypes.ADD_CALL;
    constructor(public call: CallModel) {}
}

export class AddMany implements Action {
    readonly type = CalllistActionTypes.ADD_CALLS;
    constructor(public calls: Array<CallModel>) {}
}

export class UpsertOne implements Action {
    readonly type = CalllistActionTypes.UPSERT_CALL;

    constructor(public call: CallModel) {}
}

export class UpsertMany implements Action {
    readonly type = CalllistActionTypes.UPSERT_CALLS;
    constructor(public calls: Array<CallModel>) {}
}

export class UpdateOne implements Action {
    readonly type = CalllistActionTypes.UPDATE_CALL;
    constructor(
        public id: number,
        public changes: Partial<CallModel>,
    ) {}
}
export class UpdateMany implements Action {
    readonly type = CalllistActionTypes.UPDATE_CALLS;
    constructor(public updates: Array<Update<CallModel>>) {}
}

export class DeleteOne implements Action {
    readonly type = CalllistActionTypes.DELETE_CALL;
    constructor(public id: number) {}
}

export class DeleteMany implements Action {
    readonly type = CalllistActionTypes.DELETE_CALLS;
    constructor(public ids: Array<number>) {}
}

export class DeleteState implements Action {
    readonly type = CalllistActionTypes.DELETE_STATE;
    constructor() {}
}

export type CalllistActions =
    | AddOne
    | AddMany
    | UpsertOne
    | UpsertMany
    | DeleteOne
    | DeleteMany
    | DeleteState
    | UpdateOne
    | UpdateMany
    | UpdateSelectedCallId;
