import { Injectable } from '@angular/core';
import * as callActions from './call.actions';
import * as callReducers from './call.reducers';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CallModel } from '@smarttask-common/src/lib/models/CRM/Call/Recording/CallModel';

@Injectable({
    providedIn: 'root',
})
export class CalllistStoreService {
    constructor(private callStore: Store<callReducers.CalllistState>) {}

    watchlist = (): Observable<CallModel[]> => {
        return this.callStore.select(callReducers.selectAll);
    };
    watchSelectedCallId = (): Observable<number> => {
        return this.callStore.select(callReducers.getSelectedCallId);
    };
    watchSelectedCall = (): Observable<CallModel> => {
        return this.callStore.select(callReducers.getSelectedCall);
    };

    getlist = (): Array<CallModel> => {
        var call = new Array<CallModel>();
        this.callStore
            .select(callReducers.selectAll)
            .pipe(take(1))
            .subscribe((x) => {
                call = x;
            });
        return call;
    };

    getByCallId = (userCallConfig_id: number): CallModel | undefined => {
        var call = new Array<CallModel>();
        this.callStore
            .select(callReducers.selectAll)
            .pipe(take(1))
            .subscribe((x) => {
                call = x;
            });
        return call.find((x) => x.call_id == userCallConfig_id);
    };
    getSelected = (): CallModel => {
        var userCallConfig: CallModel;
        this.callStore
            .select(callReducers.getSelectedCall)
            .pipe(take(1))
            .subscribe((x) => (userCallConfig = x));
        return userCallConfig;
    };
    getSelectedCallId = (): number => {
        var selected_call_id: number;
        this.callStore
            .select(callReducers.getSelectedCallId)
            .pipe(take(1))
            .subscribe((x) => (selected_call_id = x));
        return selected_call_id;
    };
    updateSelectedCallId = (userCallConfig_id: number) => {
        this.callStore.dispatch(new callActions.UpdateSelectedCallId(userCallConfig_id));
    };

    upsertOne = (userCallConfig: CallModel) => {
        this.callStore.dispatch(new callActions.UpsertOne(userCallConfig));
    };
    upsertMany = (userCallConfigs: Array<CallModel>) => {
        this.callStore.dispatch(new callActions.UpsertMany(userCallConfigs));
    };

    updateOne = (userCallConfigId: number, changes: Partial<CallModel>) => {
        this.callStore.dispatch(new callActions.UpdateOne(userCallConfigId, changes));
    };
    updateMany = (userCallConfigs: Array<Update<CallModel>>) => {
        this.callStore.dispatch(new callActions.UpdateMany(userCallConfigs));
    };

    deleteOne = (userCallConfigId: number) => {
        this.callStore.dispatch(new callActions.DeleteOne(userCallConfigId));
    };
    deleteMany = (ids: number[]) => {
        this.callStore.dispatch(new callActions.DeleteMany(ids));
    };
    deleteAll = () => {
        var list = this.getlist();
        this.callStore.dispatch(new callActions.DeleteMany(list.map((x) => x.call_id)));
    };
}
