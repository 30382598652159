import * as actions from './call.actions';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';
import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { CallModel } from '@smarttask-common/src/lib/models/CRM/Call/Recording/CallModel';

export const callAdapter = createEntityAdapter<CallModel>({
    selectId: (x) => x.call_id,
});
export interface CalllistState extends EntityState<CallModel> {
    selected_call_id: number;
}

export const initialState: CalllistState = callAdapter.getInitialState({
    selected_call_id: undefined,
});

export function CalllistReducers(state: CalllistState = initialState, action: actions.CalllistActions) {
    switch (action.type) {
        case actions.CalllistActionTypes.UPDATE_SELECTED_ID:
            state = { ...state, selected_call_id: action.selected_call_id };
            return state;

        case actions.CalllistActionTypes.ADD_CALL:
            return callAdapter.addOne(action.call, { ...state, selected_call_id: state.selected_call_id });

        case actions.CalllistActionTypes.ADD_CALLS:
            return callAdapter.addMany(action.calls, { ...state, selected_call_id: state.selected_call_id });

        case actions.CalllistActionTypes.UPSERT_CALL:
            return callAdapter.upsertOne(action.call, { ...state, selected_call_id: state.selected_call_id });

        case actions.CalllistActionTypes.UPSERT_CALLS:
            return callAdapter.upsertMany(action.calls, { ...state, selected_call_id: state.selected_call_id });

        case actions.CalllistActionTypes.UPDATE_CALL:
            return callAdapter.updateOne(
                {
                    id: action.id,
                    changes: action.changes,
                },
                { ...state, selected_call_id: state.selected_call_id },
            );

        case actions.CalllistActionTypes.UPDATE_CALLS:
            return callAdapter.updateMany(action.updates, { ...state, selected_call_id: state.selected_call_id });

        case actions.CalllistActionTypes.DELETE_CALL:
            // if(state.selected_call_id == action.id){
            //   state.selected_call_id = undefined;
            // }
            return callAdapter.removeOne(action.id, { ...state, selected_call_id: state.selected_call_id });

        case actions.CalllistActionTypes.DELETE_CALLS:
            // if(action.ids.indexOf(state.selected_call_id) > -1){
            //   state.selected_call_id = undefined;
            // }
            return callAdapter.removeMany(action.ids, { ...state, selected_call_id: state.selected_call_id });

        case actions.CalllistActionTypes.DELETE_STATE:
            state.selected_call_id = undefined;
            return callAdapter.removeAll(state);

        default:
            return state;
    }
}

export const getCalllistState = createFeatureSelector<CalllistState>('calllist');

const getSelectedCallIdState = (state: CalllistState) => {
    return state.selected_call_id;
};
export const getSelectedCallId = createSelector(getCalllistState, getSelectedCallIdState);

export const getSelectedCall = createSelector(getCalllistState, (state: CalllistState) => {
    if (state.selected_call_id == undefined) {
        return undefined;
    }
    return state.entities[state.selected_call_id];
});

export const { selectIds, selectEntities, selectAll, selectTotal } = callAdapter.getSelectors(getCalllistState);
